import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import getImage from '@solid-ui-components/utils/getImage'
const ContentImages = ({
  content: { images },
  reverse,
  imagePosition,
  imageFit,
  imageEffect,
  loading,
  threshold
}) =>
  images ? (
    <>
      <Reveal
        effect={imageEffect || (reverse ? 'fadeInRight' : 'fadeInLeft')}
        threshold={threshold}
        css={css({
          textAlign:
            imagePosition === 'center' ? 'center' : reverse ? `right` : `left`
        })}
      >
        {images?.[0]?.src && (
          <div css={css({borderRadius:"10px",overflow:"hidden"})}>
          <Img
            image={getImage(images[0].src)}
            alt={images[0].alt}
            loading={loading}
            objectFit={imageFit}
            css = {{height:"100%",display:"block"}}
          />
          </div>
        )}
      </Reveal>
      {images?.slice(1)?.map(
        ({ src, position = {}, effects = [], alt }, index) =>
          src && (
            <Reveal
              key={`item-${index}`}
              effect={effects[0] || undefined}
              delay={0.5}
              threshold={1}
              css={css({
                ...position,
                position: `absolute`,
                display: [`none`, `block`],
                zIndex:10,
                width:"200px",
                borderRadius:"10px"
              })}
              
              >
              <Reveal
                effect={effects[1] || undefined}
                style={{ backfaceVisibility: `hidden`,zIndex:10,borderRadius:"10px" }}
              >
                <div css={css({borderRadius:"10px",overflow:"hidden"})}>
                <Img image={getImage(src)} alt={alt} css={css({borderRadius:"10px"})} />
                </div>
              </Reveal>
            </Reveal>
          )
      )}
    </>
  ) : null

ContentImages.defaultProps = {
  loading: 'lazy',
  threshold: 0.7
}

export default ContentImages
